<template>
  <MitraPemasokForm mode="Ubah" module="Mitra Pemasok"> </MitraPemasokForm>
</template>

<script>
import MitraPemasokForm from './form';

const MitraPemasokUpdate = {
  name: 'MitraPemasokUpdate',
  components: { MitraPemasokForm },
};

export default MitraPemasokUpdate;
</script>
